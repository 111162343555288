// UserPerspectives.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './StoryDetail.css'; // Import the CSS file
import './UserPerspectives.css'; // Import the CSS file
import CircleLoader from 'react-spinners/CircleLoader';

const UserPerspectives = ({ storyId }) => {
  const [userPerspectives, setUserPerspectives] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserPerspectives = async () => {
      setLoading(true); // Start loading
      try {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/build_user_perspectives/${storyId}`);
        const perspectivesResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user_perspectives/story/${storyId}`);
        setUserPerspectives(perspectivesResponse.data);
      } catch (error) {
        console.error('Error fetching user perspectives:', error);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchUserPerspectives();
  }, [storyId]);

  if (loading) {
    return (
      <div className="user-perspectives">
        <h3 className="section-header">Users Perspectives</h3>
        <div className="loading-container">
          <CircleLoader color="#ff6600" size={24} />
          <span>Filtering users comments for their opinions...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="user-perspectives">
    <h3 className="section-header">Users Perspectives</h3>
    {userPerspectives.map((perspective, index) => (
      <div key={perspective.id} className={`perspective-container ${index % 2 === 0 ? 'left' : 'right'}`}>
        <div className="perspective-username">{perspective.username}</div>
        <div className={`perspective-item ${index % 2 === 0 ? 'left' : 'right'}`}>
          {perspective.perspectives}
        </div>
      </div>
    ))}
  </div>
  );
};

export default UserPerspectives;