// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="app-footer">
      <nav>
        <ul>
          <li><Link to="/About">About</Link></li>
          <li><Link to="/Acknowledgement">Acknowledgement</Link></li>
          <li><Link to="/Contact">Contact Us</Link></li>
        </ul>
      </nav>
    </footer>
  );
};

export default Footer;