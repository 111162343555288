import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faComment } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Contact.css';

const Contact = () => {
  const [state, handleSubmit] = useForm("xjkgrpao"); // Use your Formspree form ID

  if (state.succeeded) {
    return (
      <div className="contact-container">
      <FontAwesomeIcon icon={faCheckCircle} size="3x" color="#28a745" />
      <h2>Thank you!</h2>
      <p className="return-home">
          <Link to="/">← Return to Homepage</Link>
      </p>
    </div>
    );
  }

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">
            <FontAwesomeIcon icon={faUser} />
            Name:
          </label>
          <input
            id="name"
            type="text" 
            name="name"
            required
          />
          <ValidationError 
            prefix="Name" 
            field="name"
            errors={state.errors}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="email">
            <FontAwesomeIcon icon={faEnvelope} />
            Email:
          </label>
          <input
            id="email"
            type="email"
            name="email"
            required
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="message">
            <FontAwesomeIcon icon={faComment} />
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            rows="5"
            required
          />
          <ValidationError 
            prefix="Message" 
            field="message"
            errors={state.errors}
          />
        </div>
        
        <button 
          type="submit" 
          className="submit-button"
          disabled={state.submitting}
        >
          {state.submitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default Contact;